<template>
  <v-container class="altback" fluid fill-height>
    <v-row class="justify-center">
      <v-col cols="12" sm="8" md="6" lg="5" xl="4">
        <router-view/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions} from 'vuex'
import {getApplications} from '@/services/applications'

export default {
  beforeMount() {
    this.initApplications();
  },
  
  methods: {
    ...mapActions(['setApplication']),

    async initApplications() {
      const {application} = this.$route.query

      const applications = await getApplications().then((data) => data);
      if (!applications) return;

      this.setApplication(applications.find(e => e.name === application) || applications[0]);
    }
  }
};
</script>

<style>
</style>